import store from "@/store/index";

export function useCleanStore() {
  //   const store = useStore();
  // store.commit("resetStateCampiLocked");
  store.commit("resetFiltersEconomato");
  //   store.commit("resetFiltersAffiliazioniRaas"); Raas da ultimare
  store.commit("resetFiltersAlboSnm");
  store.commit("resetFiltersAlboInsegnante");
  store.commit("resetFiltersAlbo");
  store.commit("resetFiltersAlboSnm");
  store.commit("resetFiltersImpAss");
  store.commit("resetFiltersBacheca");
  store.commit("resetFiltersComitati");
  store.commit("resetFiltersUtentiComitati");
  store.commit("resetFiltersCompensazioni");
  store.commit("resetFiltersCorsiAreaInsegnante");
  store.commit("resetFiltersCorsi");
  store.commit("resetFiltersCorsoPartecipanti");
  store.commit("resetFiltersDettaglioImportazione");
  store.commit("resetFiltersDocPersona");
  store.commit("resetFiltersDocSoc");
  store.commit("resetFilterEconomatoPersonale");
  store.commit("resetFiltersEconomatoSoc");
  store.commit("resetFiltersEsportazioni");
  store.commit("resetFiltersQuote");
  store.commit("resetFiltersHPAnagrafiche");
  store.commit("resetFiltersHPRichieste");
  store.commit("resetFiltersImportazioni");
  store.commit("resetFiltersMovimenti");
  store.commit("resetFiltersUtentiOmologatori");
  store.commit("resetFiltersOmologList");
  store.commit("resetFiltersPagamenti");
  store.commit("resetFiltersUtentiPersone");
  store.commit("resetFiltersPrivacyTesSoc");
  store.commit("resetFiltersRanking");
  store.commit("resetFiltersReportPretesseramento");
  store.commit("resetReports");
  store.commit("resetFiltersRichiesteUnaSoc");
  store.commit("resetFiltersRinnovo");
  store.commit("resetFiltersSaldo");
  store.commit("resetFiltersSanzioni");
  store.commit("resetFiltersSanzioni");
  store.commit("resetFiltersSanzioniUnaSoc");
  store.commit("resetFiltersScuole");
  store.commit("resetFiltersUtentiSocieta");
  store.commit("resetFiltersTesseramenti");
  store.commit("resetFiltersTesseratiFederali");
  store.commit("resetFiltersTesseratoSoc");
  store.commit("resetFiltersTesserato");
  store.commit("resetFiltersTessereSpeciali");
  store.commit("resetFiltersTrasf");
  store.commit("resetFiltersTrasfRichieste");
  store.commit("resetFiltersTrasferimentiUnaSoc");
  store.commit("resetFiltersComitatoUtenti");
  store.commit("resetFiltersVivaio");
  //   store.commit("resetMenu");
}
