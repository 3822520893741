// COMPOSABLE
// IMPORT LIBRERIE VARIE
import { ref, computed, onUpdated } from "vue";
import { useStore } from "vuex";

export function useRedirectInsegnanti() {
  // DICHIARAZIONE VARIABILI
  const store = useStore();
  const pagesLength = ref(null);
  const redirectInsegnanti = ref(null);
  const menuLoaded = computed(() => store.getters.isMenuLoaded);
  const menu = computed(() => store.getters.menu);
  const hasBacheca = ref(false);
  const hasAlbo = ref(false);
  const isLoggingAdb2c = computed(() => store.getters.isLoggingAdb2c);

  if (!menuLoaded.value && !isLoggingAdb2c.value) {
    store.dispatch("getMenuFromApi", 0);
  }

  onUpdated(() => {
    if (menuLoaded.value) {
      hasBacheca.value = menu.value[0].pages.some((element) => {
        return (
          element.heading && element.heading.includes("bacheca-insegnante")
        );
      });
      hasAlbo.value = menu.value[0].pages.some((element) => {
        return element.heading && element.heading.includes("albo");
      });
      pagesLength.value = menu.value[0].pages.length;
      if (pagesLength.value === 1 || !hasBacheca.value)
        redirectInsegnanti.value = menu.value[0].pages[0].route;
    }
  });

  return {
    pagesLength,
    redirectInsegnanti,
    menuLoaded,
    hasBacheca,
    hasAlbo,
  };
}
