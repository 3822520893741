
import { defineComponent, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import isEnabled from "@/composables/isEnabled.js";
import { useMsal } from "@/composition-api/useMsal";

import { useCleanStore } from "@/composables/emptyStore";

export default defineComponent({
  name: "error-404",
  components: {},
  setup() {
    const store = useStore();
    const { instance } = useMsal();

    const { redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    const errorMenu = computed(() => store.getters.errorMenu);

    const signOut = async () => {
      const idTokenHint = localStorage.getItem("tokenAdb2c") as string;
      localStorage.clear();
      useCleanStore();
      await instance.logoutRedirect({
        idTokenHint: idTokenHint,
      });
    };

    return {
      getIllustrationsPath,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      isEnabled,
      errorMenu,
      signOut,
      goToFch: computed(() => localStorage.getItem("goToFch")),
    };
  },
});
