import store from "@/store/index";

export default function isEnabled(fnName) {
  if (!store.state.CampiLocked.readOnlySelectedRoute) {
    return store.state.CampiLocked.campiEnabled.some((campoEnabled) => {
      return campoEnabled.nc === fnName;
    });
  } else {
    // return false;
    return store.state.CampiLocked.campiEnabled.some((campoEnabled) => {
      return campoEnabled.nc === `nrd_${fnName}`;
    });
  }
}
